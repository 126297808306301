.tx-vhw-readmore {
  margin-bottom: 13px;
}
.tx-vhw-readmore .elements .element + .element {
  margin-top: 13px;
}
.tx-vhw-readmore .elements .element.js-active button.expand {
  display: none;
}
.tx-vhw-readmore .intro span,
.tx-vhw-readmore .text p,
.tx-vhw-readmore button.expand,
.tx-vhw-readmore button.collapse {
  font-size: 15px;
  line-height: 24px;
}
.tx-vhw-readmore .intro p:last-of-type,
.tx-vhw-readmore .text p:last-of-type {
  display: inline;
}
.tx-vhw-readmore .intro {
  margin-bottom: 13px;
}
.tx-vhw-readmore .text {
  display: none;
}
.tx-vhw-readmore button:focus {
  outline: none;
}
.tx-vhw-readmore button.expand,
.tx-vhw-readmore button.collapse {
  color: #0080bc;
  cursor: pointer;
  padding: 0;
  background: transparent;
  text-align: left;
}
.tx-vhw-readmore button.expand:before,
.tx-vhw-readmore button.collapse:before,
.tx-vhw-readmore button.expand:after,
.tx-vhw-readmore button.collapse:after {
  display: none;
}
