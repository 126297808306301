@import "_globals.less";

.tx-vhw-readmore {
	margin-bottom: 13px;
	
	.elements {
		
		
		.element {
			
			+ .element {
				margin-top: 13px;
			}
			
			&.js-active {
				button.expand {
					display: none;
				}
			}
		}
	}
	
	.intro span,
	.text p,
	button.expand,
	button.collapse {
		font-size: 15px;
		line-height: 24px;
	}
	
	.intro,
	.text {
		p {
			&:last-of-type {
				display: inline;
			}
		}
	}
	
	.intro {
		margin-bottom: 13px;
	}
	
	.text {
		display: none;
	}
	
	button {
		&:focus {
			outline: none;
		}
		
		&.expand,
		&.collapse {
			color: #0080bc;
			cursor: pointer;
			padding: 0;
			background: transparent;
			text-align: left;
			
			&:before,
			&:after {
				display: none;
			}
		}
		
		&.expand {
			
		}
		
		&.collapse {
			
		}
	}
}